const articleSchema = (
  url,
  title,
  image,
  metaDate,
  metaUpdateDate,
  metaDescription,
  metaCategory
) => {
  const schema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Organization",
        "@id": "https://nizam.app/#organization",
        name: "Nizam",
        url: "https://nizam.app/",
        sameAs: [
          "https://www.facebook.com/nizamapppt",
          "https://www.instagram.com/nizam.app.pt",
        ],
        logo: {
          "@type": "ImageObject",
          "@id": "https://nizam.app/#logo",
          inLanguage: "pt-BR",
          url: "https://nizam.app/logo192.png",
          width: 570,
          height: 180,
          caption: "Nizam",
        },
        image: {
          "@id": "https://nizam.app/#logo",
        },
      },
      {
        "@type": "WebSite",
        "@id": "https://nizam.app/#website",
        url: "https://nizam.app/",
        name: "Nizam",
        inLanguage: "pt-BR",
        description:
          "O Nizam é um aplicativo gratuito onde você pode aprender um pouco mais sobre o Islam todos os dias",
        publisher: {
          "@id": "https://nizam.app/#organization",
        },
        potentialAction: {
          "@type": "SearchAction",
          target: "https://nizam.app/search?s={search_term_string}",
          "query-input": "required name=search_term_string",
        },
      },
      {
        "@type": "ImageObject",
        "@id": `${url}/#primaryimage`,
        inLanguage: "pt-BR",
        url: image,
        width: 1200,
        height: 628,
        caption: title,
      },
      {
        "@type": "WebPage",
        "@id": `${url}/#webpage`,
        url: url,
        name: title,
        isPartOf: {
          "@id": "https://nizam.app/#website",
        },
        inLanguage: "pt-BR",
        primaryImageOfPage: {
          "@id": `${url}/#primaryimage`,
        },
        datePublished: metaDate,
        dateModified: metaUpdateDate,
        description: metaDescription,
      },
      {
        "@type": "Article",
        "@id": `${url}/#article`,
        isPartOf: {
          "@id": `${url}/#webpage`,
        },
        author: {
          "@id":
            "https://nizam.app/#/schema/person/13bd408ff759a4d0c4cad95ac0b4ed06",
        },
        headline: title,
        datePublished: metaDate,
        dateModified: metaUpdateDate,
        commentCount: 0,
        mainEntityOfPage: {
          "@id": `${url}/#webpage`,
        },
        publisher: {
          "@id": "https://nizam.app/#organization",
        },
        image: {
          "@id": `${url}/#primaryimage`,
        },
        articleSection: metaCategory,
        inLanguage: "pt-BR",
      },
      {
        "@type": ["Person"],
        "@id":
          "https://nizam.app/#/schema/person/13bd408ff759a4d0c4cad95ac0b4ed06",
        name: "A Redação",
        description:
          "A Equipe de Redação do Nizam é multidisciplinar e composta por profissionais da área de Marketing, Ilustração/Design, História, Administração, Gestão de Recursos Humanos, Tradutores Especializados (Árabe e Inglês) e especialistas na Religião Islâmica. Atualmente a equipe é composta por 10 profissionais.",
        sameAs: [],
      },
    ],
  }

  return schema
}

export default articleSchema
